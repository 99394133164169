<template>
  <div class="bodywhite">
    <div class="container">
      <div class="paddingtop80">
        <h1 class="fontcolor-mainblue">Trage deine einzunehmenden Medikamente ein.</h1>
      </div>
    </div>

    <div class="container paddingtop90">
      <a href="registrationusername">
        <button type="button" value="Submit" class="buttonhoch backgroundcolor-mainblue">
          <p class="psmallbold fontcolor-white">Hinzufügen</p>
        </button>
      </a>
    </div>

    <div class="stepnavigation backgroundcolor-beige">
      <div class="container">
        <button type="button" value="Submit" class="buttonXS backgroundcolor-white col-2 col-center center" @click="registrationmedicationzuregistrationtime()">
          <img class="navicon:active" src="../assets/icons/arrow.svg">
        </button>
        <button type="button" value="Submit" class="buttonS fontcolor-white backgroundcolor-mainblue" @click="registrationmedicationzuregistrationhome()">
          <p class="pbold display-inline fontcolor-white">Weiter</p>
          <img class="display-inline" src="../assets/icons/arrow_white.svg" alt="">
        </button>
      </div>
    </div>

  </div>
</template>

<style scoped>



::placeholder {
  color: #7C8489;
  opacity: 1;
}

</style>

<script>
  import { mapActions } from 'vuex';
  import router from '../router';
  import FootNavigationVue from "../components/FootNavigation.vue";

  export default {
    name: 'information',
    components: {
      'foot-navigation' : FootNavigationVue,
    },
    methods: {
      ...mapActions(['loadUserData']),
      registrationmedicationzuregistrationtime() {
        router.push({path: 'registrationtime'})
      },
      registrationmedicationzuregistrationhome() {
        router.push({path: '/'})
      },
    }
  };
</script>
